import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { Header } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSection, PageSizing } from "component/app/items";
import { Tap } from "component/basic/tap";
import { FooterManu } from "component/elements/footer";
import { WashListBox, WashListItem, WashTypeCaption } from "component/wash/item";
import { bottomChk } from "js/function";
import { NotItems } from "component/basic/notItems";

const WashTicket = (props) => {
    const navigate = useNavigate();

    const [page,setPage] =  useState(1);

    const washApi = useGet({
        url:`/car/wash?page=${page}`,
        loginType:"login"
    });

    const washData = washApi?.data?.car_wash_reservations;

    const [itemList,setItemList] =  useState([]);

    let lastPage = false;
    let settingChk = 0;

    function addPage(){
        setPage(page + 1);
        settingChk = 1;
        setTimeout(() => {
            settingChk = 0;
        }, 300);
    };

    useEffect(() => {
        if(washData?.data && washData?.data.length > 0){
            if(page == 1){
                setItemList([...washData?.data]);
            }else{    
                setItemList(prev => [...prev, ...washData?.data]);
            }
        }else{
            setItemList([]);
        }

        lastPage = washData?.current_page < washData?.last_page;

        window.removeEventListener("scroll", handleScroll);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [washApi]);

    function handleScroll(){
        if(bottomChk() && lastPage && settingChk == 0){
            addPage();
        }
    };

    useEffect(() => {
        setItemList([]);
        const timer = setInterval(() => {
            window.addEventListener("scroll", handleScroll);
        }, 100);
        return () => {
            clearInterval(timer);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <PageSizing addClass="headerPage">
            <Header
                pageBack={false}
                headTitle={null}
            >
                <Tap
                    addClass=""
                    func={(id)=>{
                        navigate(`/${id}/ticket`)
                    }}
                    val="wash"
                    data={[
                        {id:"wash",category:"카워시"},
                        {id:"detailing",category:"디테일링"},
                        // {id:"dentrepair",category:"덴트리페어"}
                    ]}
                />
            </Header>
            <ContentsSection addClass="headerContents footerContents">
                <WashListBox>
                    {itemList && itemList?.length > 0 ?
                        <>
                            {itemList?.map((item,i)=>(
                                <WashListItem 
                                    key={i}
                                    id={item.id}
                                    name={<>{item?.package_reservation_id ? ('') : (<WashTypeCaption type={item.type == "once" ? "1회권" : "구독권"} addClass={item.type == "once" ? "bColor" : "gColor"}/>)}{item.name}</>}
                                    packageItems={item.package_products||[]}
                                    caption={item.contents||""}
                                    option={item.product_options||[]}
                                    subOptionTitle={item.relation_product_name||null}
                                    subOption={item.relation_product_options||[]}
                                    include_inside_full_care={item.include_inside_full_care}
                                    inside_full_care_option_count={item.inside_full_care_option_count}
                                    use_once={null}
                                    use_month={null}
                                    price={item.price}
                                    month_price={item.month_price}
                                    isUse={item.status == 0 ? false : true}
                                    btn0Text={item.status == 0 ? "사용가능" : item.status == 1 ? "사용완료" : item.status == 3 ? "취소요청" : item.status == 4 ? "취소완료" : item.status == 5 ? "기간만료" : item.status == 6 ? "선물완료" : ""}
                                    ticketType={true}
                                />
                            ))}
                        </>
                        :
                        <NotItems
                            addClass="fullPage"
                            img="/assets/images/icon/not_event.svg"
                            text="이용가능한 이용권이 없습니다."
                        />
                    }
                </WashListBox>
            </ContentsSection>
            <FooterManu menuChk="2"/>
        </PageSizing>
    );
};

export default WashTicket;